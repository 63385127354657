// src/components/Nodes.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Spinner, Alert } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import MainContractABI from '../abis/MainContractABI';
import NodesABI from '../abis/NodesABI';
import SETTINGS from '../SETTINGS';
import { ERC20_ABI } from "../abis/erc20";
import { useContract } from '../hooks/useContract';
import { useNavigate } from 'react-router-dom';
import boxImg from '../images/box.png';
const Nodes = ({ provider, account }) => {
    
    const [quantities, setQuantities] = useState([]);
    const { collections, fetchCollections } = useContract(provider);
    const [loading, setLoading] = useState(false);
    const [buyAvailable, setBuyAvailable] = useState(false);
    const [txMessage, setTxMessage] = useState('');
    const [reffCode, setReffCode] = useState('');
    const [reffAddress, setReffAddress] = useState('');

    const navigate = useNavigate();

    
    useEffect(() => {
        setQuantities(collections.map(() => 1));  // Default quantity for each collection
    }, [collections]);
    
    const incrementQuantity = (index) => {
        setQuantities(qts => qts.map((qty, idx) => idx === index ? qty + 1 : qty));
    };
    
    const decrementQuantity = (index) => {
        setQuantities(qts => qts.map((qty, idx) => idx === index && qty > 1 ? qty - 1 : qty));
    };
    const _setReffCode = async (code) => {
        setReffCode(code);
        if(code.length < 3) return;
        
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const nftContract = new Contract(
            SETTINGS.nodeContract,
            NodesABI,
            signer
        );

        // Call the buy function on the NFT contract
        // Since we're only working with USDT, we don't need to send ETH value
        const refferalAddr = await nftContract.inviteCode(code);
        if (refferalAddr.toLowerCase() === "0x0000000000000000000000000000000000000000") {
            setBuyAvailable(false);
          } else {
            setBuyAvailable(true);
          }
        setReffAddress(refferalAddr);
    };
    
    const buyNFT = async (index) => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        if (!collections || index >= collections.length) return;
    
        const quantity = quantities[index];
        const usdtAmount = parseInt(collections[index].priceUSDTWei) * parseInt(quantity);
       

        console.log(usdtAmount);
        try {
            setLoading(true);
            setTxMessage("Approving USDT transfer...");
    
            // Connect to the USDT contract
            const usdtContract = new Contract(
                SETTINGS.usdtAddress,
                ERC20_ABI,
                signer
            );
    
            // Approve the NFT contract to spend USDT on behalf of the user
            const approveTx = await usdtContract.approve(
                SETTINGS.nodeContract,
                usdtAmount
            );
            await approveTx.wait();
            
            setTxMessage("Processing the purchase...");
    
            // Connect to the NFT contract and buy the NFT
            const nftContract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );
    
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const buyTx = await nftContract.buy(quantity, usdtAmount, reffAddress);
            await buyTx.wait();
    
            setTxMessage("NFT successfully purchased!");
        } catch (error) {
            console.error("Error purchasing NFT:", error);
            setTxMessage("Failed to purchase NFT. Please try again.");
        } finally {
            await fetchCollections();
            setLoading(false);
            navigate('/');
            // Optionally reset the transaction message after a delay
            setTimeout(() => setTxMessage(""), 5000);
            
        }
    };
    
    
      if (loading) {
        return (
            <div className="loaderScreen text-center">
              <br />
             <br />
              <br />
             <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }
    
      if (collections.length === 0) {
        return (
            <div className="loaderScreen text-center">
              <br />
            <br />
              <br />
            <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }
    return (
    
            <div className="buy-token-container1">
            <div className="buy-token-header text-center">
            
            </div>
      
                <Row className="mb-3">
                <Col sm={12} md={3} lg={4}></Col>
            {collections.map((collection, index) => (
                 <Col sm={12} md={6} lg={4} key={index} >
                    <h4>Buy Node</h4>
                <Card className="nft-card  pokemon-card">
                
                <Card.Img variant="top" src={boxImg} /> 
                    {/*  <div className="iframe-container">
                        <iframe src={SETTINGS.modelUrl} title="External Content" />
                    </div>
                    */}
                    <Card.Header className="pokemon-card-title">{collection.name} </Card.Header>
                    
                    <Card.Body className="pokemon-card-body text-center">
                        <Card.Text>
                            <Row>
                            <Col sm={4} >
                        <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"  style={{width:"30px", marginRight:"5px",  marginleft:"20px", marginTop:"1px"}} /> 
                        </Col><Col sm={8} >
                       <h6 style={{marginTop:"5px"}}> {collection.priceUSDT * quantities[index]} <small>USDT</small> </h6>
                           </Col>
                           
                       
                            </Row>
                        </Card.Text>
                      
                    </Card.Body>
                    <Card.Footer className="pokemon-card-footer">
                        {collection.isAvailable ? (
                       <>
                       <Row className='mb-3'>
                       <Col sm={5}>
                        <Button variant="outline-secondary" className='buton' onClick={() => decrementQuantity(index)}>-</Button>
                        </Col>
                        <Col sm={2}>
                        <div className='pokemon-card-title'>{quantities[index]}</div>
                        </Col>
                        <Col sm={5}>
                        <Button variant="outline-secondary" className='buton' onClick={() => incrementQuantity(index)}>+</Button>
                        </Col>
                    </Row>
                    <div className='input-group'>
                    <input
                    style={{width:"100%", textAlign:"center"}}
                        type="text"
                        value={reffCode}
                        onChange={(e) => _setReffCode(e.target.value)}
                        placeholder="Discount Code"

                    />
                    </div>
                    {buyAvailable ? (
                        <Button variant="primary" onClick={() => buyNFT(index)}  className='buton mb-3'>
                                        BUY NODE
                                    </Button> 
                    ):(<></>)}
                                   </>
                                    ):(<Button variant="primary" disabled className='buton butonDisabled'>
                                    {collection.availableToMint > 0 ? (<>Not opened</>):(<>SOLD OUT</>)}
                                </Button>)}
                                   
                    </Card.Footer>
                </Card>
                </Col>
            ))}
            
            </Row>
           </div>
       
    );
};

export default Nodes;
